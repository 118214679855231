


require("bootstrap/dist/css/bootstrap.css")
require("bootstrap/dist/js/bootstrap")
require("./src/styles/global.css")

// exports.shouldUpdateScroll = () => { return false; };
exports.shouldUpdateScroll = () => {
window.scrollTo({ top:0, left:0, behavior: "instant"});
    return false;
};
