exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-featured-detail-js": () => import("./../../../src/pages/featured/detail.js" /* webpackChunkName: "component---src-pages-featured-detail-js" */),
  "component---src-pages-featured-index-js": () => import("./../../../src/pages/featured/index.js" /* webpackChunkName: "component---src-pages-featured-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-sites-js": () => import("./../../../src/pages/info/sites.js" /* webpackChunkName: "component---src-pages-info-sites-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news/detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-videos-detail-js": () => import("./../../../src/pages/videos/detail.js" /* webpackChunkName: "component---src-pages-videos-detail-js" */),
  "component---src-pages-videos-index-js": () => import("./../../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */)
}

